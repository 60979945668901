
.logout-btn{
	background-color: #005499 !important;
    border: none !important;
}
.signup-btn{
    background-color: #00d14f !important;
    border: none !important;
}
.logout-btn:hover,.signup-btn:hover{
    color: rgba(255, 255, 255, 0.74) !important;
}