/* General Layout */
.signup-container {
  display: flex;
  height: 70vh;
}

.signup-left {
  width: 40%;
  height: 100vh;
  background: linear-gradient(
      180deg,
      rgba(15, 90, 160, 0.336),
      rgba(52, 232, 100, 0.679)
    ),
    url("../../../asset/images/mfi-2.jpg"); /* Replace with your image */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
}

.signup-left h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-align: center;
}

.signup-left p {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
}

.signup-right {
  width: 60%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.signup-form {
  width: 100%;
  padding: 25px 200px;
  border-radius: 10px;
  background-color: #ffffff;
}

.signup-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 0px;
}

.details-title {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #276292;
}

.signup-form-border {
  border: 1px solid #cbd5e0;
  border-radius: 10px;
  padding: 20px;
}

.signup-form-group {
  display: flex;
  gap: 10px;
}

.error-text {
  color: red;
  font-size: 0.7rem;
  margin-bottom: 0;
  margin-top: 0;
}

.form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}

.form-field label {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 5px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.eye-icon {
  position: absolute;
  right: 2px;
  top: 7px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.location-section label {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 5px;
}

.form-field input,
.form-field select {
  width: 100%;
  padding: 8px;
  border: 1px solid #cbd5e0;
  background-color: #f8fbfc;
  border-radius: 5px;
  font-size: 0.8rem;
}

.signup-button {
  width: 100%;
  padding: 15px;
  background-color: #015498;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signup-button:hover {
  background-color: #61b847;
}

.signup-button:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
}

.navigate-link {
  color: #494949;
  font-size: 0.9rem;
  margin-top: 7px;
}

.navigate-link b {
  color: rgb(16, 159, 216);
  cursor: pointer;
}

.navigate-link b:hover {
  color: rgb(0, 183, 255);
  cursor: pointer;
  text-decoration: underline;
}

.loginpage-link {
  color: #494949;
  font-size: 0.9rem;
  margin-top: 5px;
}

.loginpage-link b {
  color: rgb(16, 159, 216);
  cursor: pointer;
}

.loginpage-link b:hover {
  color: rgb(0, 183, 255); /* Change text color */
  cursor: pointer; /* Change cursor to pointer on hover */
  text-decoration: underline; /* Add underline effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column; /* Stack the left and right sections */
  }

  .signup-left,
  .signup-right {
    width: 100%;
    height: auto; /* Adjust height to content */
  }

  .signup-left {
    padding: 50px;
    text-align: center;
  }

  .signup-left h1 {
    font-size: 2rem;
  }

  .signup-left p {
    font-size: 1rem;
  }

  .signup-right {
    padding: 20px;
  }

  .signup-form {
    padding: 15px;
    box-shadow: none;
  }

  .signup-title {
    font-size: 1.5rem;
  }

  .signup-form-group {
    flex-direction: column;
    gap: 0px;
  }

  .form-field {
    margin-bottom: 15px; /* Add margin for better spacing */
  }

  .form-field input,
  .form-field select {
    font-size: 1rem; /* Increase input size for readability */
  }

  .signup-button {
    font-size: 1rem; /* Adjust button size */
    padding: 12px; /* Adjust button padding */
  }

  .navigate-link,
  .loginpage-link {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .signup-left {
    padding: 50px;
  }

  .signup-title {
    font-size: 1.3rem;
  }

  .form-field input,
  .form-field select {
    font-size: 1rem;
    padding: 10px;
  }

  .signup-button {
    font-size: 0.9rem;
    padding: 12px;
  }

  .account-link {
    font-size: 0.8rem;
  }

  .navigate-link {
    font-size: 0.8rem;
  }

  .loginpage-link {
    font-size: 0.8rem;
  }
}

.autocomplete-items {
  width: 28.8%; /* Adjust width as per input field */
  max-height: 150px; /* Limit the height */
  overflow-y: auto; /* Enable scrolling */
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  background: white;
  z-index: 10; /* Keep suggestions above other content */
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-items div.autocomplete-active {
  background-color: #f0f0f0; /* Highlight active suggestion */
}
