

.nav-button {
	transition: background-color 0.3s, border 0.3s;
	background-color: #61B847;
  }


.active {
	
	/* border: 1px solid white; */
	font-weight: 800; /* Set background color for active button */
  }

.button-container {
	display: flex;
	justify-content: center;
	gap: 1px;
	/* flex-wrap: wrap; Wraps the buttons if screen is too small */
  }

.custom-form {
	margin-top: 20px;
}

.form-group {
	margin-bottom: 20px;
}

.form-group label {
	display: block;
	font-weight: bold;
}

.form-input {
	width: 100%;
	height: 35px;
	padding: 5px;
	font-size: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.form-input-textarea {
	width: 100%;
	height: 80px;
	padding: 5px;
	font-size: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.form-button {
	background-color: #4caf50;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.form-button:hover {
	background-color: #45a049;
}

.card-container:hover .card {
	transform: scale(1.03);
	background-color: #c8e6c9;
	border-color: #81c784;
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-section {
	display: flex;
}
.custom-container {
	justify-content: space-between;
}

.text-container {
	padding: 20px;
}

.form-container {
	width: 50%;
	padding: 20px;
}

.line-text {
	font-weight: thin;
	font-size: 65px;
	background: #14ab19; /* Replace with your desired gradient */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.w33 {
	width: 33%;
}
.container-my {
	transform: scale(1.03);
	background-color: #c8e6c9;
	border-color: #81c784;
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.tc {
	display: flex;
}
.text-head {
	font-size: 50px;
}
@media only screen and (max-width: 576px) {
	.text-container {
		width: fit-content;
	}
	.form-input {
		width: 100%;
		height: 25px;
		padding: 5px;
		font-size: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.custom-container {
		justify-content: space-between;
	}
	.form-group {
		margin-bottom: 5px;
	}

	.text-container {
		margin-left: 5px;
		margin-right: 5px;
		padding: 10px;
	}
	.icon-section {
		display: block;
	}
	.form-input-textarea {
		width: 100%;
		height: 50px;
		padding: 5px;
		font-size: 10px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.w33 {
		width: 100%;
	}
	.tc {
		display: block;
	}
	.rand {
		margin-left: 5px;
		margin-right: 5px;
	}
	.text-head {
		font-size: 25px;
	}
}

  
  /* Media query for screens smaller than 800px */
  @media (max-width: 576px) {
	.button-container {
		width: 370px;
	  	justify-content: space-around; /* Align buttons evenly */
	  	flex-wrap: nowrap; /* No wrapping, keeps buttons in a single row */
	}

	.nav-button {
		font-size: 11px;
		transition: background-color 0.3s, border 0.3s;
		background-color: #14ab19;
	  }
  }
  