/* Custom CSS for ResourceCard */
.resource-card-up {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.border-custom {
  border-bottom: 1px solid #ccc;
  background-color: #005499;
  width: 50px;
  margin: 0 auto;
}

.spn-top-line {
  color: #005499;
  font-size: 16px;
  font-weight: bold;
}

.spn-top-line-head {
  color: #005499;
  font-size: 26px;
  font-weight: bold;
}

.ftco-section .overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	opacity: .8;
	background: #000000;
	background: #207dff;
	background: -moz-linear-gradient(left, #207dff 0%, #a16ae8 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, #207dff), color-stop(100%, #a16ae8));
	background: -webkit-linear-gradient(left, #207dff 0%, #a16ae8 100%);
	background: -o-linear-gradient(left, #207dff 0%, #a16ae8 100%);
	background: -ms-linear-gradient(left, #207dff 0%, #a16ae8 100%);
	background: -webkit-gradient(linear, left top, right top, from(#207dff), to(#a16ae8));
	background: linear-gradient(to right, #207dff 0%, #a16ae8 100%);
	background: linear-gradient(to right, #005499 0%, #61B847 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207dff', endColorstr='#a16ae8', GradientType=1);
}
.img-fluid {
  width: 150px;
  height: 150px;
  border: 2px solid #005499;
}
