/* Modal Container */
.modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

/* Modal Box */
.modal-box {
    background-color: white;
    padding: 45px 20px;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 22rem;
    height: 36vh;
    text-align: center;
    position: relative;
    max-width: 100%; /* Ensures modal doesn't overflow the viewport */
    box-sizing: border-box; /* Includes padding in width calculation */
}

/* Modal Header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 20px;
    right: 20px;
}

/* Modal Body */
.modal-body {
    margin-top: 30px;
}

/* Modal Title */
.modal-title {
    font-size: 2rem;
    font-weight: bold;
}

/* Close Button */
.modal-close {
    font-size: 2rem;
    cursor: pointer;
}

/* Modal Text */
.modal-text {
    font-size: 12px;
}

/* Modal Item */
.modal-item {
    border: 1px solid rgba(234, 238, 242, 0.914);
    border-radius: 0.5rem;
    background-color: white;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.5s, color 0s;
}

/* Last Modal Item */
.modal-item:last-child {
    margin-bottom: 0;
}

/* Hover Effects */
.modal-item.job:hover {
    background-color: #015498;
    color: white;
}

.modal-item.talent:hover {
    background-color: #61B847;
    color: white;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .modal-box {
        width: 85%; /* Increases modal width to take up more space on mobile */
        height: auto; /* Let the height grow based on content */
        max-height: 80vh; /* Limits modal height to 80% of the viewport */
        padding: 25px 15px; /* Reduces padding for mobile */
    }

    .modal-title {
        font-size: 1.5rem; /* Adjust title font size for smaller screens */
    }

    .modal-close {
        font-size: 1.5rem; /* Adjust close button font size */
    }

    .modal-text {
        font-size: 10px; /* Reduces text size for better mobile readability */
    }

    .modal-item {
        font-size: 14px; /* Adjust font size of modal items for mobile */
    }

    .modal-item.job:hover,
    .modal-item.talent:hover {
        background-color: #015498; /* Keep the hover colors for mobile */
        color: white;
    }
}

@media (max-width: 480px) {
    .modal-box {
        width: 90%; /* Further increases modal width on smaller screens */
        padding: 20px; /* Further reduces padding for small screens */
    }

    .modal-title {
        font-size: 1.3rem; /* Further reduces title font size for tiny screens */
    }

    .modal-close {
        font-size: 1.3rem; /* Adjusts close button font size for mobile */
    }

    .modal-text {
        font-size: 9px; /* Further reduces text size for smaller screens */
    }

    .modal-item {
        font-size: 12px; /* Adjusts font size of items */
        padding: 8px; /* Adds some padding to modal items for smaller screens */
    }
}
