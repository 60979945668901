/* Main container */
.payment-page-container {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  height: auto; 
  margin-bottom: 20px;
  box-sizing: border-box; /* Includes padding in the height calculation */
}


/* Payment Section styles */
.payment-section {
  padding: 30px;
  width: 60%; /* Full width */
  height: auto; /* Adjust based on content */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
  padding-bottom: 1.5%;
  background-color: #fff;
}

/* Center content inside payment-section */
.payment-section .header h3 {
  font-size: 1.9rem; /* Adjusted for better visibility */
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: 650;
  margin-top: 0%;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-underline {
  width: 90px;
  height: 2px;
  background-color: #4CAF50;
  margin-top: 5px;
}

/* Form section */
.form-section {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 15px;
}

.form-controls {
  width: 70%; /* Full width */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: 15px;
}

/* Payment options */
.payment-options {
  margin-bottom: 20px;
}

.form-checks {
  margin-bottom: 8px;
}

.form-check-inputs {
  margin-right: 10px;
  margin-left: 15px;
  width: 17px; 
  height: 15px; 
  cursor: pointer; 
}


.form-check-label {
  font-size: 18px;
  font-weight: 450;
}

.custom-amount {
  margin-top: 10px;
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
}

.error-message {
  color: red;
  margin-top: 5px;
}

/* Payment footer */
.payment-footer {
  margin-top: 50px;
  display: flex;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* Disclaimer section */
.disclaimer {
  font-size: 0.8rem;
  color: #555;
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
}

.disclaimer .icon {
  margin-right: 4px;
  font-size: 0.8rem;
  color: #4CAF50; /* Adjust color to match the design */
}


.pay-methods {
  width: 80%;
  margin-top: 20px;
}

.pay-button {
  background: linear-gradient(to right, rgb(0, 221, 255), rgb(18, 239, 18));
  color: white;
  width: 100%;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.secure-payment {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.secure-payment svg {
  margin-right: 5px;
}

.fin-logo {
  margin-top: 20px;
  width: 600px; 
  height: auto; 
}


/* Pricecard within Box 1 */
.pricecard {
  position: absolute;
  top: 14%; /* Adjust top position as needed */
  right: 10%; /* Adjust right position as needed */
  width: 400px; /* Adjust the width as needed */
  height: 57vh;
  padding: 10px; /* Adjust height if necessary */
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.734);
  display: flex;
  flex-direction: column;
  overflow: auto; /* Handle overflow */
}

/* Pricecard styles */
.pricecard-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.pricecard-header {
  border-bottom: 1px solid #ddd;
}


.pricecard-header h4 {
  font-size: 28px;
  font-weight: 650;
  margin-top: 4%;
  margin-bottom: 2%;
}

.pricecard-body {
  padding: 10px;
  flex: 1; /* Takes up the remaining space */
}

.detail-row {
  display: flex;
  justify-content: space-between;
}

.total-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0;
}

.label {
  font-weight: bold;
}

.value {
  font-size: 16px;
}

.pay-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust font size if needed */
  padding: 10px 20px; /* Adjust padding for better alignment */
}

.pay-button .spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-radius: 50%;
  border-top: 2px solid #3498db; /* Blue */
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 8px; /* Space between icon and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Media queries for responsiveness */
/* Mobile view (up to 576px) */
@media (max-width: 576px) {
  .payment-page-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    padding: 15px; /* Center-align content */
  }

  .payment-section {
    padding: 20px;
    width: 100%;
    margin: 0;
    margin-top: 20px; /* Add some padding on the sides */
  }

  .payment-footer {
    margin-top: 15px;
  }

  .pricecard {
    position: relative; /* Remove absolute positioning */
    width: 100%; /* Full width for mobile */
    margin: 20px 0; /* Space between payment section and price card */
    top: auto; /* Remove any top alignment */
    right: auto; /* Remove right alignment */
    height: auto; /* Allow height to adjust based on content */
  }

  .form-label {
    margin-left: 0px;
    margin-top: 10px;
  }
  
  .form-controls {
    width: 100%;
    margin: 0;
  }

  .pay-button {
    font-size: 14px;
    padding: 10px;
  }

  .pricecard {
    width: 100%;
    margin-top: 20px;
  }

  .pricecard-header h4 {
    font-size: 1.5rem; /* Smaller header font for mobile */
  }
  
  .pricecard-body {
    font-size: 0.9rem; /* Adjust body text size for readability */
  }

  .fin-logo {
    width: 330px;
  }
}


/* Tablet view (between 577px and 1024px) */
@media (min-width: 577px) and (max-width: 1024px) {
  .payment-section {
    width: 80%; /* Adjust width for tablet */
  }

  .pricecard {
    width: 300px; /* Adjust width for tablet */
    height: auto; 
  }

  .payment-section .header h3 {
    font-size: 1.7rem; /* Slightly smaller font for header */
  }

  .pay-button {
    font-size: 15px;
  }
}

/* Laptop and larger (1025px and above) */
@media (min-width: 1025px) {
  .payment-section {
    width: 60%; /* Larger width for desktop */
  }

  .pricecard {
    width: 400px;
  }

  .payment-section .header h3 {
    font-size: 1.9rem; /* Larger font for desktop */
  }

  .pay-button {
    font-size: 16px;
  }
}

