/* Row Styling */
.row {
    display: flex;
    flex-wrap: wrap; /* Ensure cards wrap on smaller screens */
    margin: 0;
    justify-content: center;
    padding: 20px 70px; /* Adjust padding to ensure proper spacing */
}

/* Card Styling */
.plan-card {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: space-between; /* Space content evenly */
    height: auto; /* Allow height to adjust based on content */
    background-size: cover;
    background-position: center;
    position: relative; /* Ensure button is placed properly */
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure Buttons Are Always Clickable */
.plan-card .button {
    z-index: 10; /* Ensure button is on top */
    position: relative; /* Maintain normal flow */
}

/* Card Sizes */
.col-md-4 {
    padding: 0;
    flex: 1 1 calc(33.333% - 20px); /* Three columns layout with spacing */
    max-width: calc(33.333% - 20px); /* Adjust width for spacing */
    margin-bottom: 20px; /* Space between rows */
}

/* Left Card Styling */
.col-md-4:not(.middle-card) .plan-card.left-card {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Right Card Styling */
.col-md-4:not(.middle-card) .plan-card.right-card {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Middle Card Styling */
.col-md-4.middle-card .plan-card {
    color: white; /* Set text color to white for the middle card */
    background-color: #61B847; /* Set background color to green for the middle card */
    background-image: none; /* Ensure no background image is set */
}

.col-md-4:not(.middle-card) .plan-card {
    background-color: #F8F8F8; /* Set background color to light grey for other cards */
}

.col-md-4.middle-card .partner-features-list{
    margin-left: 50px;
}
/* Card Header Styling */
.Styled-pt-3 {
    text-align: center;
    font-weight: bold;
    background-color: #01549800;
    border-bottom: 1px solid rgb(193, 193, 193);
}

/* Additional Styling for Card Title */
.payment-card-title .MuiCardHeader-title {
    font-size: 1.7rem;
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: bold; /* Ensure the font weight is bold */
}

/* Card Content Styling */
.Styled-px-2 {
    position: absolute; /* Absolute positioning within the card */
    top: 25%; /* Adjust positioning */
    left: 0;
    right: 0;
    bottom: 60px; /* Adjust bottom to make space for the button */
    padding: 0 20%; /* Adjust padding as needed */
    background: rgba(255, 255, 255, 0); 
}

/* Middle Card Specific Styling */
.col-md-4.middle-card .plan-card .Styled-px-2 {
    padding: 0 20%; /* Set padding to 0,30% for the middle card */
    bottom: 40px; /* Adjust bottom spacing to make space for the button */
}

.plan-card h3 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    color: inherit; /* Inherit text color from parent */
}

.plan-card h6 {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 3px;
    color: inherit; /* Inherit text color from parent */
}

.plan-card h1 {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 3px;
    color: inherit; /* Inherit text color from parent */
}

/* Button Styling */
.col-md-4:not(.middle-card) .plan-card .button {
    background-color: #61B847; /* Set button color to green for left and right cards */
    color: #fff;
    border: none;
    margin-top: 20px; /* Space between content and button */
    padding: 14px 18px;
    border-radius: 4px;
    font-weight: bold;
    width: 60%;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover transition */
    align-self: center; /* Center button horizontally */
}

/* Hover effects and disabled button styling */
.col-md-4:not(.middle-card) .plan-card .button:not(:disabled):hover {
    background-color: #0056b3; /* Darker green on hover */
}
.col-md-4:not(.middle-card) .plan-card .button:disabled {
    background-color: #55A23E; /* Grey background for disabled state */
    color: rgb(43, 71, 37); /* Grey text for disabled state */
    cursor: not-allowed; /* Show not-allowed cursor */
}

/* Middle Card Button Styling */
.col-md-4.middle-card .plan-card .buttons {
    background-color: #F8F8F8; /* Set button color to white for the middle card */
    color: #000; /* Text color can be adjusted if needed */
    border: none;
    margin-bottom: 40px;
    width: 60%;
    padding: 14px 18px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
}

/* Hover effects and disabled button styling for middle card */
.col-md-4.middle-card .plan-card .button:not(:disabled):hover {
    background-color: #0056b3;
    color: white; /* White text on hover */
}
.col-md-4.middle-card .plan-card .button:disabled {
    background-color: #d0cfcf;
    color: rgb(126, 126, 126);
    cursor: not-allowed;
}

.pricelabel{
    font-size: 22px;
    margin-left: 15px;
    font-weight: 600;
}
/* Link Styling */
.Styled-mt-2 {
    margin-top: 16px;
    text-align: center;
}

a {
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
}

a:hover {
    color: #0056b3;
}

/* Responsive Adjustments */

/* Large Devices (Laptop) */
@media (max-width: 1200px) {
    .row {
        padding: 20px 40px; /* Adjust padding for laptops */
    }
    .col-md-4 {
        flex: 1 1 calc(50% - 20px); /* Two columns layout */
        max-width: calc(50% - 20px);
    }
}

/* Responsive Adjustments */

/* Extra Small Devices (Very Small Mobile) */
/* Extra Small Devices (Very Small Mobile) */
@media (max-width: 576px) {
    /* Adjust row padding for smaller screens */
    .row {
        padding: 10px 20px; /* Reduce the padding on small screens */
    }

    /* Make the columns stack vertically */
    .col-md-4 {
        flex: 1 1 100%; /* Ensure columns take full width */
        max-width: 100%; /* Full width for small screens */
        margin-bottom: 20px; /* Add space between stacked cards */
    }

    /* Adjust the size of the plan cards */
    .plan-card {
        height: 70vh; /* Allow cards to adjust height based on content */
        padding: 10px; /* Reduce padding inside the card */
    }

    /* Adjust header font size */
    .plan-card h3 {
        font-size: 1.8rem; /* Smaller font size for headers */
        margin-bottom: 20px; /* Reduce bottom margin */
        margin-top: 25px;
    }

    /* Adjust subheader font size */
    .plan-card h6 {
        font-size: 0.9rem;
        padding-top: 6px; /* Smaller font size for subheaders */
    }

    /* Adjust button size */
    .button {
        padding: 10px 16px; /* Slightly reduce button padding */
        font-size: 0.9rem; /* Adjust font size for buttons */
        width: 70%; /* Adjust button width */
    }

    /* Adjust card content padding */
    .Styled-px-2 {
        padding: 0 10%; /* Reduce padding inside the card */
        bottom: 40px; /* Adjust spacing for the button */
    }

    /* Ensure consistent spacing between cards */
}

