/* Base styles */
body {
  font-family: Arial, sans-serif;
}

/* General Team Container */
.team-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

/* Team Member Box */
.team-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f8f8f8;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-box:hover {
  transform: scale(1.02);
}

/* Team Member Image */
.team-image {
  width: 100%;
  max-width: 120px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
}

.team-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Content Styling */
.team-content-right {
  text-align: center;
}

.team-name {
  font-size: 1.25rem;
  font-weight: bold;
}

.team-designation {
  font-size: 0.875rem;
  color: #666;
}

.team-description {
  font-size: 1rem;
  color: #333;
}

/* Responsive Styles */

/* Mobile (Default - single card per row) */
@media (max-width: 639px) {
  .team-container {
    padding: 8px;
  }

  .team-content-right {
    text-align: left;
    padding-left: 16px;
  }

  .team-box {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  .team-name {
    font-size: 1.5rem;
  }

  .team-description {
    font-size: 1.125rem;
  }

  .team-image {
    width: 120px;
    height: 120px;
  }

  /* Ensuring only 1 card per row in mobile */
  .team-row {
    display: block;
    width: 100%;
  }
}

/* For medium screens (tablets and larger) */
@media (min-width: 640px) {
  .team-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .team-box {
    width: 48%;
  }

  .team-name {
    font-size: 1.5rem;
  }

  .team-description {
    font-size: 1.125rem;
  }

  .team-image {
    width: 160px;
    height: 160px;
  }
}

/* For large screens (laptops and larger) */
@media (min-width: 1024px) {
  .team-row {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .team-box {
    width: 30%;
  }

  .team-name {
    font-size: 1.75rem;
  }

  .team-description {
    font-size: 1.25rem;
  }

  .team-image {
    width: 200px;
    height: 200px;
  }
}

/* Additional Hover Effects for Larger Screens */
@media (hover: hover) {
  .team-box:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
}
