/* General Layout */
.login-container {
  display: flex;
}

.login-left {
  width: 40%;
  height: 100vh; /* Full height for left section */
  background: linear-gradient(
      180deg,
      rgba(15, 90, 160, 0.336),
      rgba(52, 232, 100, 0.679)
    ),
    url('../../../asset/images/loginbgimage.webp');
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.login-left h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-align: center;
}

.login-left p {
  font-size: 1.2rem;
  text-align: center;
}

.login-right {
  width: 60%;
  height: 100vh; /* Full height for right section */
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.login-form {
  width: 100%;
  max-width: 400px;
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.login-form h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.login-form-border {
  border: 1px solid rgb(197, 196, 196);
  border-radius: 10px;
  padding: 20px;
}

.login-label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: #000000;
  font-size: 1rem;
}

.login-input {
  width: 100%;
  padding: 9px;
  margin-bottom: 10px;
  border: 1px solid rgba(30, 30, 30, 0.6);
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: #f8fbfc; /* Light background for inputs */
}

.password-container {
  position: relative;
  width: 100%;
}

.password-input {
  padding-right: 40px; /* Add space for the toggle icon */
}

.password-toggle {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #015498;
}

.password-toggle i {
  font-size: 0.8rem;
}

.login-button {
  width: 100%;
  padding: 12px;
  margin-top: 7px;
  background-color: #015498;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #61b847;
}

.login-button:disabled {
  background-color: #61b847;
  cursor: not-allowed;
}

.back-to-home {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #61b847;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.back-to-home:hover {
  background-color: #0056b3;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.forgot-password {
  background: none;
  border: none;
  color: #015498;
  font-size: 0.9rem;
  cursor: pointer;
}

.forgot-password:hover {
  color: rgb(28, 140, 184);
  cursor: pointer;
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

.remember label{
  font-size: 1rem;
}

.login-button:disabled {
  color: #d1c8c8;
  background-color: #51a857;  /* डार्क ग्रे रंग */
  cursor: not-allowed;  /* पॉइंटर को नॉट-अलाउड पर बदलना */
}


.create-account-link {
  color: #494949;
  font-size: 0.9rem;
  margin-top: 10px;
  display: flex; /* Add flexbox for layout */
  align-items: center; /* Align items vertically center */
  gap: 35px; /* Add space between elements */
}


.create-account-link b {
  color: #015498;
  cursor: pointer;
  font-weight: 100;
}

.create-account-link b:hover {
  color: rgb(28, 140, 184);
  cursor: pointer;
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left,
  .login-right {
    width: 100%;
    height: auto; /* Allow height to adjust to content */
  }

  .login-left {
    padding: 20px;
    order: 1; 
    height: 50vh;
  }
 

  .login-right {
    padding: 20px;
    order: 2; /* Right section appears below */
  }

  .login-left h1 {
    font-size: 2rem; /* Smaller heading for mobile */
  }

  .login-left p {
    font-size: 1rem; /* Adjust paragraph text size */
  }

  .login-form {
    padding: 15px;
    box-shadow: none; /* Remove shadow for smaller screens */
  }

  .login-form h2 {
    font-size: 1.5rem; /* Adjust form title size */
  }

  .login-label {
    font-size: 0.9rem; /* Smaller label text */
  }

  .login-input {
    padding: 10px; /* Slightly larger padding for easier tapping */
    font-size: 1rem; /* Larger font for readability */
  }

  .create-account-link {
    gap: 22px;
  }
  
  .password-toggle i {
    font-size: 1rem; /* Larger icon for mobile */
  }
  
  .login-button {
    font-size: 0.9rem; /* Smaller button font */
    padding: 12px; /* Smaller button padding */
  }
  
  .forgot-password {
    font-size: 0.7rem; /* Smaller font for links */
  }
  
  .form-options {
    flex-direction: row; /* Stack forgot password and create account links */
    align-items: flex-start;
  }
  
  .create-account-link b {
    font-size: 0.7rem; /* Make the 'Create Account' link more visible */
  }
}

/* Responsive Design for 425px */
@media (max-width: 425px) {
  .login-left {
    padding: 10px;
    height: 45vh; /* Smaller height */
  }
  
  .login-left h1 {
    font-size: 1.6rem; /* Smaller heading */
  }
  
  .login-left p {
    font-size: 0.8rem; /* Smaller paragraph text */
  }
  
  .login-form {
    max-width: 300px; /* Further adjust form size */
    padding: 10px;
  }
  
  .login-input {
    padding: 8px; /* Adjust input padding */
    font-size: 0.8rem; /* Smaller font size */
  }
  
  .login-button {
    padding: 8px; /* Smaller button padding */
  }

  .create-account-link {
    gap: 12px;
    font-size: 0.7rem;
  }
}

/* Responsive Design for 375px */
@media (max-width: 375px) {
  .login-left {
    height: 40vh; 
    padding: 8px;
  }

  .login-left h1 {
    font-size: 1.5rem; /* Smaller heading */
  }

  .login-left p {
    font-size: 0.75rem; /* Smaller paragraph text */
  }

  .login-form {
    max-width: 280px; /* Smaller form size */
    padding: 8px;
  }

  .login-label {
    font-size: 0.7rem; /* Smaller label text */
  }

  .login-input {
    font-size: 0.75rem; /* Adjust input font size */
  }

  .login-button {
    font-size: 0.8rem; /* Adjust button font size */
  }

  .create-account-link {
    gap: 7px;
  }
}
