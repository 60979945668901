.profile-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.profile-card {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.profile-card__left {
  flex: 0 0 auto;
  margin-right: 20px;
  text-align: center;
}

.profile-card__photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-card__right {
  flex: 1 1 auto;
}

.profile-card__details {
  margin-bottom: 20px;
}

.profile-card__detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-card__label {
  flex: 0 0 120px;
  font-weight: bold;
}

.profile-card__value {
  flex: 1 1 auto;
}

.profile-card__actions {
  display: flex;
  align-items: center;
}

.profile-card__dropdown {
  margin-left: auto;
}

.profile-card__select {
  padding: 5px 10px;
}
.card--rejected {
  background-color: #f26b8a;
}

.card--job-offered {
  background-color: rgb(84, 244, 244);
}
.card--other {
  background-color: #32ec2fa9;
}
.name{
  color: rgb(0, 0, 0);
  font-size: large;
}
.applied-user-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


/* Adjust the width and margin values to suit your needs */


@media only screen and (max-width: 767px) {
  .profile-card {
    max-width: 100%;
  }
  
  .profile-card__left {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .profile-card__right {
    flex: 1 1 100%;
  }
  
  .profile-card__label {
    flex: 0 0 80px;
  }
}